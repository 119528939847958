export default {
  label: '数组',
  type: 'array',
  property: '',
  value: [],
  child: [],
  options: {
    note: '',
    limit: 10
  }
}
