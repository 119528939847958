export default {
  label: '数字',
  type: 'number',
  property: '',
  value: 0,
  options: {
    min: 0,
    max: 50
  }
}
