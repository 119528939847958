export default {
    label: '是否',
    type: 'switch',
    property: '',
    value: false,
    options: {
        trueLabel: "显示",
        falseLabel: "隐藏",
    }
}
