export default [
  'icon-sousuo',
  'icon-shangpin-meau',
  'icon-cart',
  'icon-message',
  'icon-sort',
  'icon-my',
  'icon-daojishi',
  'icon-biaotizujian',
  'icon-gonggao',
  'icon-line',
  'icon-image',
  'icon-swiper',
  'icon-daohang',
  'icon-lajitong',
  'icon-zujian',
  'icon-shoupiao',
  'icon-vip',
  'icon-map',
  'icon-attachent',
  'icon-hot1'
]
